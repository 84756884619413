.footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem;
  }
  