/* General Styles */
body {
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: Arial, sans-serif;
  background-color: #87CEFA;
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #87CEFA;
  overflow: hidden; /* Prevent any overflow issues */
  width: 100%;
}

.hero-content {
  position: relative; /* Change from absolute to relative */
  z-index: 2;
  width: 375px; 
  max-width: 100%; 
  height: 375px; 
  text-align: center;
  background-size: auto; 
  background-repeat: no-repeat;
  background-position: center center;
  color: black;
  margin: 2 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 70%;
}

.hero-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.8rem;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: baseline;
  margin: 0;
  margin-left: 40px; /* Shift the paragraph to the right */
}

.hero-content h1 .name-highlight {
  color: #6c63ff; /* The color used for "Zach" */
}

.hero-content p .typed-text-container {
  color: #6c63ff; /* Apply the same color to the typed text */
}

.typed-text-container {
  display: inline-block;
  white-space: nowrap;
  margin-left: 4px;
}

.name-highlight {
  color: #6c63ff; /* Keep the name highlight color */
}

.hero-content p {
  font-size: 1.5rem;
}

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cloud {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px; /* Add bottom margin to space from the edge */
}

.icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.icon:hover {
  transform: scale(1.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

