/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #333;
  padding: 15px;
  text-align: center;
  z-index: 1000;
  box-sizing: border-box;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.nav-links {
  display: flex;
  justify-content: center;
}

.nav-links a {
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  position: relative;
  transition: all 0.3s ease;
}

.nav-links a::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-links a:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-links a:hover {
  transform: translateY(-5px);
  color: #f0f0f0; 
}

/* Responsive styling */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    margin-top: 10px;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    margin: 10px 0;
  }
}
